@import url(https://fonts.googleapis.com/css?family=ABeeZee&display=swap);
*,
html,
body {
  margin: 0;
  padding: 0;
  font-family: "ABeeZee", sans-serif;
}

body {
  background-color: rgb(246, 251, 255);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.site {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-template-rows: auto auto auto;
  grid-gap: 5px;
  grid-template-areas:
    "title title title"
    "sidebar sidebar sidebar"
    "header main main "
    "footer main main "
    ".  main main "
    "CodelinesCopyrigth CodelinesCopyrigth CodelinesCopyrigth";
}

#Servicios {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-gap: 2px;

  grid-template-areas:
    "servicetitle servicetitle servicetitle"
    "descripcion servicio1 servicio2"
    "servicio3 servicio4 servicio5 "
    "servicio6 servicio7 servicio8 ";
}

.smallmap {
  display: none;
}

.descripcion {
  grid-area: descripcion;
  display: flex;
  justify-content: center;
  align-items: center;
}
.servicetitle {
  grid-area: servicetitle;
}

.servicio1 {
  grid-area: servicio1;
}

.servicio2 {
  grid-area: servicio2;
}
.servicio3 {
  grid-area: servicio3;
}
.servicio4 {
  grid-area: servicio4;
}
.servicio5 {
  grid-area: servicio5;
}
.servicio6 {
  grid-area: servicio6;
}
.servicio7 {
  grid-area: servicio7;
}
.servicio8 {
  grid-area: servicio8;
}

@media screen and (max-width: 795px) {
  .site {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "title"
      "sidebar"
      "header"
      "main"
      "footer"
      "CodelinesCopyrigth";
  }

  #Servicios {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    grid-gap: 2px;

    grid-template-areas:
      "servicetitle"
      "descripcion"
      "servicio1"
      "servicio2"
      "servicio3"
      "servicio4"
      "servicio5 "
      "servicio6 "
      "servicio7 "
      "servicio8 ";
  }

  .largemap {
    display: none;
  }
  .smallmap {
    display: block;
  }
  .sidebar {
    height: 50px;
  }
}

.masthead {
  background-color: #dcdde1;
  grid-area: header;
  margin: 1rem;
  padding: 1rem;
}

.page-title {
  grid-area: title;
  display: flex;
  /*padding: 1rem;*/
  justify-content: center;
  align-items: center;
}

.main-content {
  background-color: rgba(250, 255, 240, 0.383);
  grid-area: main;
  /*margin: 1rem !important;
  padding: 1rem !important;*/
}

.sidebar {
  background-color: rgb(255, 255, 255);
  grid-area: sidebar;
}

.footer {
  /*background-color: red;*/
  /*margin: 1rem;
  padding: 1rem;*/
  grid-area: footer;
}

.CodelinesCopyrigth {
  grid-area: CodelinesCopyrigth;
  background-color: black;
  color: whitesmoke;
  min-height: 5rem;
  height: 5rem;
}

h2::after {
  content: "";
  background: rgb(107, 18, 107);
  display: block;
  width: 100%;
  height: 10px;
}

#Servicios {
  /*background-image: url("../src/assets/promotions/mainbackground.jpg");*/
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
}

#Servicios .overlay {
  padding: 2.5rem;
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: justify;
  counter-reset: counter-name;
  /*background-image: linear-gradient(
    rgb(255, 255, 255) 25%,
    rgba(8, 8, 0, 0.26)
  );*/
  /*background-color: black;
  color: white;*/
}

#SobreNosotros {
  justify-content: center;
  align-items: center;
  height: 100%;
}

#SobreNosotros::before {
  display: block;
  height: 10px;
  width: 0%;
  background-color: black;
  content: "";
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  margin-bottom: 2rem;
}

.counter-item {
  position: relative;
  padding-left: 1rem;
}
.counter-item::before {
  position: absolute;
  left: -1.5rem;
  top: 1.2px;
  background: rgb(38, 0, 255);
  padding: 0.5em;
  color: white;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid rgb(61, 27, 255);
  box-sizing: border-box;
  counter-increment: counter-name;
  content: counter(counter-name) " ";
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.counter-item:hover::before {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.ServiceCard {
  background-color: rgba(199, 255, 252, 0.335);
  border-radius: 2%;
  box-sizing: border-box;
  border: 2px solid gray;
  width: 100%;
  position: relative;
}

.ServiceCard::before {
  position: absolute;
  left: 50%;
  top: -2rem;
  height: 4rem;
  width: 4rem;
  background-image: url(/static/media/dentist.efd7bb6d.svg);
  background-size: 4rem 4rem;
  display: inline-block;

  content: "";
}

.card1 {
  display: block;
  position: relative;
  max-width: 100%;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.card1::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #00838d;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.card1:hover::before {
  -webkit-transform: scale(35);
          transform: scale(35);
}

.card1:hover p {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}
.card1:hover h3 {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  color: #ffffff;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

ul li img {
  max-width: 1.5rem;
  margin: 0.2rem;
}

